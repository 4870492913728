@import "~antd/lib/style/themes/default.less";

:global {
  .imageContainer {
    display: flex;
    place-items: center;
    justify-content: center;

    @media (max-width: @screen-xs) {
      padding: 0!important;
    }
  }

  .formContainer, .imageContainer {
    position: relative;
    background-color: #fff !important;
  }
}

.layout {
  :global {
    .container {
      min-height: 358px !important;
    }
  }
}

.container {
  width: 100%;
  max-width: 368px;
}

.global-layout-container {
  .dof-auth-layout-container {
    background: red;
  }
}

.logo {
  position: absolute;
  width: 144px;
  top: -59px;
  left: 0;
}

.singUp {
  transform: translateX(-12px);
}

.singIn {
  @media (max-width: @screen-xs) {
    height: 180px;
  }

  @media (max-width: 370px) {
    height: 150px;
  }
}

.appLink {
  margin-top: 10px;
  @media (max-width: @screen-xs) {
    height: 180px;
  }

  @media (max-width: 370px) {
    height: 150px;
  }
}

.close {
  position: absolute;
  top: 7px;
  right: 7px;

  button {
    padding: 4px 7px;
  }
}

@primary-color: #1890FF;@layout-body-background: #F0F2F5;@menu-collapsed-width: 70px;@layout-header-height: 55px;@layout-header-padding: 0;