/*THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY UNLESS YOU KNOW WHAT YOU ARE DOING.*/

.container {
  width: 100%
}

@media (min-width: 576px) {
  .container {
    max-width: 576px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px
  }
}

.bg-transparent {
  background-color: transparent !important
}

.bg-white {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important
}

.bg-lightPrimary {
  --bg-opacity: 1 !important;
  background-color: #E6F7FF !important;
  background-color: rgba(230, 247, 255, var(--bg-opacity)) !important
}

.hover\:bg-item-hover-bg:hover {
  background-color: var(--item-hover-bg) !important
}

.border-border-color {
  border-color: var(--border-color) !important
}

.rounded {
  border-radius: 0.25rem !important
}

.border-solid {
  border-style: solid !important
}

.border-none {
  border-style: none !important
}

.border-0 {
  border-width: 0 !important
}

.border {
  border-width: 1px !important
}

.border-r-2 {
  border-right-width: 2px !important
}

.border-b {
  border-bottom-width: 1px !important
}

.cursor-default {
  cursor: default !important
}

.cursor-pointer {
  cursor: pointer !important
}

.block {
  display: block !important
}

.inline-block {
  display: inline-block !important
}

.inline {
  display: inline !important
}

.flex {
  display: flex !important
}

.table {
  display: table !important
}

.grid {
  display: grid !important
}

.contents {
  display: contents !important
}

.hidden {
  display: none !important
}

.flex-row {
  flex-direction: row !important
}

.flex-col {
  flex-direction: column !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.place-content-center {
  place-content: center !important
}

.items-start {
  align-items: flex-start !important
}

.items-center {
  align-items: center !important
}

.content-center {
  align-content: center !important
}

.justify-start {
  justify-content: flex-start !important
}

.justify-end {
  justify-content: flex-end !important
}

.justify-center {
  justify-content: center !important
}

.justify-between {
  justify-content: space-between !important
}

.flex-1 {
  flex: 1 1 0% !important
}

.flex-grow {
  flex-grow: 1 !important
}

.float-right {
  float: right !important
}

.font-light {
  font-weight: 300 !important
}

.font-normal {
  font-weight: 400 !important
}

.font-medium {
  font-weight: 500 !important
}

.font-semibold {
  font-weight: 600 !important
}

.font-bold {
  font-weight: 700 !important
}

.h-6 {
  height: 1.5rem !important
}

.h-full {
  height: 100% !important
}

.h-screen {
  height: 100vh !important
}

.text-xs {
  font-size: 0.75rem !important
}

.text-sm {
  font-size: 0.875rem !important
}

.text-base {
  font-size: var(--text-base) !important
}

.text-lg {
  font-size: var(--font-size-lg) !important
}

.text-xl {
  font-size: 1.25rem !important
}

.text-2xl {
  font-size: 1.5rem !important
}

.text-3xl {
  font-size: 1.875rem !important
}

.leading-7 {
  line-height: 1.75rem !important
}

.leading-none {
  line-height: 1 !important
}

.m-0 {
  margin: 0 !important
}

.m-1 {
  margin: 0.25rem !important
}

.m-2 {
  margin: 0.5rem !important
}

.m-3 {
  margin: 0.75rem !important
}

.m-4 {
  margin: 1rem !important
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important
}

.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important
}

.mt-0 {
  margin-top: 0 !important
}

.mr-0 {
  margin-right: 0 !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mt-1 {
  margin-top: 0.25rem !important
}

.mr-1 {
  margin-right: 0.25rem !important
}

.mb-1 {
  margin-bottom: 0.25rem !important
}

.ml-1 {
  margin-left: 0.25rem !important
}

.mt-2 {
  margin-top: 0.5rem !important
}

.mr-2 {
  margin-right: 0.5rem !important
}

.mb-2 {
  margin-bottom: 0.5rem !important
}

.ml-2 {
  margin-left: 0.5rem !important
}

.mt-3 {
  margin-top: 0.75rem !important
}

.mr-3 {
  margin-right: 0.75rem !important
}

.mb-3 {
  margin-bottom: 0.75rem !important
}

.ml-3 {
  margin-left: 0.75rem !important
}

.mt-4 {
  margin-top: 1rem !important
}

.mr-4 {
  margin-right: 1rem !important
}

.mb-4 {
  margin-bottom: 1rem !important
}

.ml-4 {
  margin-left: 1rem !important
}

.mr-5 {
  margin-right: 1.25rem !important
}

.mb-5 {
  margin-bottom: 1.25rem !important
}

.mr-6 {
  margin-right: 1.5rem !important
}

.mb-6 {
  margin-bottom: 1.5rem !important
}

.mt-8 {
  margin-top: 2rem !important
}

.-mt-1 {
  margin-top: -0.25rem !important
}

.-ml-1 {
  margin-left: -0.25rem !important
}

.-mt-2 {
  margin-top: -0.5rem !important
}

.-ml-2 {
  margin-left: -0.5rem !important
}

.-mt-6 {
  margin-top: -1.5rem !important
}

.-ml-px {
  margin-left: -1px !important
}

.max-h-10 {
  max-height: 2.5rem !important
}

.max-h-300px {
  max-height: 300px !important
}

.max-w-xs {
  max-width: 20rem !important
}

.max-w-sm {
  max-width: 24rem !important
}

.min-h-12 {
  min-height: 3rem !important
}

.min-h-screen {
  min-height: 100vh !important
}

.min-w-8 {
  min-width: 2rem !important
}

.min-w-12 {
  min-width: 3rem !important
}

.min-w-20 {
  min-width: 5rem !important
}

.min-w-24 {
  min-width: 6rem !important
}

.min-w-48 {
  min-width: 12rem !important
}

.opacity-50 {
  opacity: 0.5 !important
}

.opacity-75 {
  opacity: 0.75 !important
}

.overflow-y-auto {
  overflow-y: auto !important
}

.overflow-x-hidden {
  overflow-x: hidden !important
}

.p-0 {
  padding: 0 !important
}

.p-1 {
  padding: 0.25rem !important
}

.p-2 {
  padding: 0.5rem !important
}

.p-4 {
  padding: 1rem !important
}

.p-6 {
  padding: 1.5rem !important
}

.p-px {
  padding: 1px !important
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important
}

.pt-0 {
  padding-top: 0 !important
}

.pt-1 {
  padding-top: 0.25rem !important
}

.pr-1 {
  padding-right: 0.25rem !important
}

.pb-1 {
  padding-bottom: 0.25rem !important
}

.pl-1 {
  padding-left: 0.25rem !important
}

.pt-2 {
  padding-top: 0.5rem !important
}

.pr-2 {
  padding-right: 0.5rem !important
}

.pb-2 {
  padding-bottom: 0.5rem !important
}

.pl-2 {
  padding-left: 0.5rem !important
}

.pt-3 {
  padding-top: 0.75rem !important
}

.pr-3 {
  padding-right: 0.75rem !important
}

.pb-16 {
  padding-bottom: 4rem !important
}

.fixed {
  position: fixed !important
}

.absolute {
  position: absolute !important
}

.relative {
  position: relative !important
}

.inset-0 {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important
}

.top-0 {
  top: 0 !important
}

.right-0 {
  right: 0 !important
}

.left-0 {
  left: 0 !important
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important
}

.text-left {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.text-right {
  text-align: right !important
}

.text-black {
  --text-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important
}

.text-white {
  --text-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--text-opacity)) !important
}

.text-error {
  color: var(--error-color) !important
}

.text-primary {
  color: var(--primary-color) !important
}

.text-success {
  color: var(--success-color) !important
}

.text-warning {
  color: var(--warning-color) !important
}

.hover\:text-primary:hover {
  color: var(--primary-color) !important
}

.italic {
  font-style: italic !important
}

.align-middle {
  vertical-align: middle !important
}

.visible {
  visibility: visible !important
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important
}

.w-16 {
  width: 4rem !important
}

.w-20 {
  width: 5rem !important
}

.w-32 {
  width: 8rem !important
}

.w-40 {
  width: 10rem !important
}

.w-56 {
  width: 14rem !important
}

.w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important
}

.w-max {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important
}

.w-full {
  width: 100% !important
}

.w-screen {
  width: 100vw !important
}

.z-10 {
  z-index: 10 !important
}

.z-30 {
  z-index: 30 !important
}

.z-40 {
  z-index: 40 !important
}

.gap-4 {
  grid-gap: 1rem !important;
  gap: 1rem !important
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important
}

.transform {
  --transform-translate-x: 0 !important;
  --transform-translate-y: 0 !important;
  --transform-rotate: 0 !important;
  --transform-skew-x: 0 !important;
  --transform-skew-y: 0 !important;
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important
}

.rotate-180 {
  --transform-rotate: 180deg !important
}

.transition-all {
  transition-property: all !important
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important
}

.duration-200 {
  transition-duration: 200ms !important
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .md\:inline {
    display: inline !important
  }

  .md\:flex {
    display: flex !important
  }

  .md\:hidden {
    display: none !important
  }

  .md\:flex-row {
    flex-direction: row !important
  }

  .md\:items-center {
    align-items: center !important
  }

  .md\:justify-between {
    justify-content: space-between !important
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .md\:mb-0 {
    margin-bottom: 0 !important
  }

  .md\:mr-2 {
    margin-right: 0.5rem !important
  }

  .md\:ml-2 {
    margin-left: 0.5rem !important
  }

  .md\:mr-3 {
    margin-right: 0.75rem !important
  }

  .md\:min-w-40 {
    min-width: 10rem !important
  }
}

@media (min-width: 992px) {
  .lg\:block {
    display: block !important
  }

  .lg\:inline-block {
    display: inline-block !important
  }

  .lg\:hidden {
    display: none !important
  }

  .lg\:flex-row {
    flex-direction: row !important
  }

  .lg\:flex-wrap {
    flex-wrap: wrap !important
  }

  .lg\:items-center {
    align-items: center !important
  }

  .lg\:font-normal {
    font-weight: 400 !important
  }

  .lg\:text-xs {
    font-size: 0.75rem !important
  }

  .lg\:text-base {
    font-size: var(--text-base) !important
  }

  .lg\:m-5 {
    margin: 1.25rem !important
  }

  .lg\:mt-0 {
    margin-top: 0 !important
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important
  }

  .lg\:ml-0 {
    margin-left: 0 !important
  }

  .lg\:mr-2 {
    margin-right: 0.5rem !important
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem !important
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem !important
  }

  .lg\:text-primary {
    color: var(--primary-color) !important
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}
