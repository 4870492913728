@import "~antd/lib/style/themes/default.less";

:global {
  .ant-form-vertical {
    overflow-x: hidden;
  }

  .form-vertical-item, .form-view-mode-vertical-item.ant-form-item-view-mode {
    .ant-form-item-label {
      width: 100%;

      label::after {
        content: '';
      }
    }
  }

  .form-item-hidden-edit-mode.ant-form-item:not(.ant-form-item-view-mode) {
    display: none;
  }

  .ant-form-item-has-error {
    .ck.ck-editor__main > .ck-editor__editable {
      border-color: @error-color !important;
    }
  }
}
