:global {
  .ant-drawer .ant-drawer-header {
    padding: 11px 24px;
  }

  .customUserRenderFieldDetails {
    form.ant-form-horizontal {
      > .ant-row:not(:first-of-type) {
        border-top: 1px solid #D9D9D9;
        height: 58px;

        &:last-child {
          border-bottom: 1px solid #D9D9D9;
        }

        .ant-col {
          display: flex;
          align-items: center;

          .ant-form-item-view-mode {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .noticeDrawerContainer {
    .ant-drawer-content-wrapper {
      max-width: 632px !important;
      width: 100% !important;
    }

    form.ant-form-horizontal {
      > .ant-row:not(:first-of-type):not(:nth-child(2)) {
        border-top: 1px solid #D9D9D9;
        min-height: 58px;

        .ant-col {
          display: flex;
          align-items: center;

          .ant-form-item-view-mode {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .memberDrawerContainer {
    .ant-drawer-content-wrapper {
      max-width: 632px !important;
      width: 100% !important;
    }

    .ant-input-number {
      width: 100% !important;
    }

    form.ant-form-horizontal {
      > .ant-row:not(:first-of-type):not(:nth-child(2)) {
        border-top: 1px solid #D9D9D9;
        min-height: 58px;

        .ant-col {

          .ant-form-item-view-mode {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .surveyDrawerContainer {
    .ant-drawer-content-wrapper {
      max-width: 632px !important;
      width: 100% !important;
    }

    form.ant-form-horizontal {
      .ant-col {
        .ant-checkbox-wrapper {
          margin-bottom: 15px;
        }
      }
    }
  }
}
